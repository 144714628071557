<template>
    <section>
        <div class="row">
            <div class="col-md-12">
                <card-pagination v-for="(section, sectionId) in sections" :key="sectionId" :title="section.sectionName">
                    <!-- Card -->
                    <span v-if="section.element == 'card'">
                        <product-card :sectionTitle="section.sectionName" :entitytype="section.entitytype" :title="section.constrains['card-title']" :price="section.constrains['card-price']"></product-card>
                    </span>
                    <!-- Carousel -->
                    <span v-else-if="section.element == 'carousel'">
                        <carousel :sectionTitle="section.sectionName" :entitytype="section.entitytype" :title="section.constrains.carousel['title']" :description="section.constrains.carousel['description']"/>
                    </span>
                    <!-- Figcaption -->
                    <span v-else-if="section.element == 'figcaption'">
                        <Figcaption :sectionTitle="section.sectionName" :entitytype="section.entitytype" :title="section.constrains.figcaption['title']" :description="section.constrains.figcaption['description']"></Figcaption>
                    </span>
                    <span v-else>Pas d'élément pour les cards</span>
                </card-pagination>
            </div>
        </div>
    </section>
</template>

<script>
import Carousel from '../../components/element/carousel.vue'
import CardPagination from '../../components/pagination/card-pagination.vue'
import ProductCard from '../../components/element/product-card.vue'
import Figcaption from '../../components/metadata/figcaption.vue'
export default 
  {
    components: { 
        Carousel,
        CardPagination,
        ProductCard,
        Figcaption
      },
    name: 'page-metadata',
    data () {
        return {
            sections: [
                {
                    sectionName: 'card-products',
                    entitytype: 'products',
                    element: 'card',
                    constrains : {
                        "card-title" : 'name',
                        "card-price": 'price',
                        "carousel-title": '',
                        "carousel-description": ''
                    }
                },
                {
                    sectionName: 'figcaption-categories',
                    entitytype: 'categories',
                    element: 'figcaption',
                    constrains : {
                        "card-title" : '',
                        "card-price": '',
                        figcaption: {
                            title: 'category',
                            description: 'description',
                            price: 15
                        }
                    }
                },
                {
                    sectionName: 'carousel-categories',
                    entitytype: 'categories',
                    element: 'carousel',
                    constrains : {
                        "card-title" : '',
                        "card-price": '',
                        carousel: {
                            title: 'category',
                            description: 'description',
                        },
                        figcaption: {
                            "figcaption-title": 'title',
                            "figcaption-description": 'description'
                        }
                    }
                }
            ],
        }
    },
    computed: {
    },
    watch: {
    },
    methods : {
    },
     mounted () {
     }
}

</script>
